import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/INSTAR_MQTT_Broker/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR MQTT on Android",
  "path": "/Advanced_User/INSTAR_MQTT_Broker/Android_Apps/",
  "dateChanged": "2019-08-20",
  "author": "Mike Polinowski",
  "excerpt": "MQTT (Message Queuing Telemetry Transport) is a machine-to-machine (M2M)/ Internet of Things (IoT) connectivity protocol. It was designed as an extremely lightweight publish/subscribe messaging transport. It is useful for connections with remote locations where a small code footprint is required and/or network bandwidth is at a premium. All Full HD cameras now offer an internal MQTT broker: IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.",
  "image": "../AU_SearchThumb_MQTT.png",
  "social": "/images/Search/AU_SearchThumb_MQTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_MQTT_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Android"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='INSTAR MQTT on Android' dateChanged='2019-08-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='MQTT (Message Queuing Telemetry Transport) is a machine-to-machine (M2M)/ Internet of Things (IoT) connectivity protocol. It was designed as an extremely lightweight publish/subscribe messaging transport. It is useful for connections with remote locations where a small code footprint is required and/or network bandwidth is at a premium. All Full HD cameras now offer an internal MQTT broker: IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.' image='/images/Search/AU_SearchThumb_MQTT.png' twitter='/images/Search/AU_SearchThumb_MQTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/INSTAR_MQTT_Broker/Android_Apps/' locationFR='/fr/Advanced_User/INSTAR_MQTT_Broker/Android_Apps/' crumbLabel="MQTT" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "instar-mqtt-on-android",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-mqtt-on-android",
        "aria-label": "instar mqtt on android permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR MQTT on Android`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#mqtt-dash-iot-smarthome"
        }}>{`MQTT Dash (IoT, Smarthome)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#mqtt-dashboard-iot-and-node-red-controller"
        }}>{`Mqtt Dashboard IoT and Node-RED controller`}</a></li>
    </ul>
    {/* /TOC */}
    <p>{`Use your INSTAR cameras MQTT interface with your Android device. There are a couple of MQTT apps available on the `}<a parentName="p" {...{
        "href": "https://play.google.com/store/apps/collection/cluster?clp=ggEGCgRNUVRU:S:ANO1ljJ06xs&gsr=CgmCAQYKBE1RVFQ%3D:S:ANO1ljJL5rU"
      }}>{`Google Play Store`}</a>{` that allow you to control your INSTAR Full HD IP camera.`}</p>
    <p>{`They all offer, more or less, the same functions and similar interfaces. In the following we are going to take a look at two of them - the `}<a parentName="p" {...{
        "href": "https://play.google.com/store/apps/details?id=net.routix.mqttdash"
      }}>{`MQTT Dash (IoT, Smarthome)`}</a>{` and `}<a parentName="p" {...{
        "href": "https://play.google.com/store/apps/details?id=com.app.vetru.mqttdashboard"
      }}>{`MQTT Dashboard - IoT and Node-RED controller`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0eaba8eaaea8b195e315f869c94199d1/a878e/MQTT_Dash_Android_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB5UlEQVQ4y4VTuW4UQRCdf0N8ASE/sH9AgAgIEQEBiSVkQoRAIMiIvEaEYA4HZo28x6w81x7jnfvorn6oereH8XgWl1Sa7urq11X13lhJkiDPc7AppbSb9f+snSuEQBRFiOMYFhFdS2obJ/K5lBKCXQjtvDeA5g7n8doyQAb492iEwWCAi8kE0/kc88tLOK6LVbDAOlhi4QeIwk1vpRrQBIi2Qcdx8PLwEItwDT+LsMpiSCL9deMrLLMYQRrBSzbIRXUNtKmwr2WpFMI8bfYPhm9w9/VT3P94gHvvn+POqyc4mp1tc1tjs7pgPB8mib0sCqRpijTLkKQp4ixFJQVqKVGTBPUQaHWHy4BlWaKsKgjJJGzJIEloE9gG6m3ZHHI1tj2H5/vIyqJfLtiSyA/trbB7oEg1UjBfLQ+lIEjquenY7kojG9aXaeU2MVPPuYnVda3HZbUrYnPPPHx6+BbBzMF4OsV4PMHo/Fxrke2nb+Pg+xFe/DjGn7V346F/pOwA/Ys1hs+GCGwXv05P8e3riQZlcLZje4RHn9/h8ZcPOPFmO0C6XYdJWcBbLZFn+d5fs28MN1jmebJEJEnkRYGqqjWr3IEkAakIgmi/DruALOh1GDaxMAy1LnXVeYJNHOnhd3VoAP8C5jCKFQtBTtYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0eaba8eaaea8b195e315f869c94199d1/e4706/MQTT_Dash_Android_01.avif 230w", "/en/static/0eaba8eaaea8b195e315f869c94199d1/d1af7/MQTT_Dash_Android_01.avif 460w", "/en/static/0eaba8eaaea8b195e315f869c94199d1/7f308/MQTT_Dash_Android_01.avif 920w", "/en/static/0eaba8eaaea8b195e315f869c94199d1/e1c99/MQTT_Dash_Android_01.avif 1380w", "/en/static/0eaba8eaaea8b195e315f869c94199d1/76ea5/MQTT_Dash_Android_01.avif 1840w", "/en/static/0eaba8eaaea8b195e315f869c94199d1/95b77/MQTT_Dash_Android_01.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0eaba8eaaea8b195e315f869c94199d1/a0b58/MQTT_Dash_Android_01.webp 230w", "/en/static/0eaba8eaaea8b195e315f869c94199d1/bc10c/MQTT_Dash_Android_01.webp 460w", "/en/static/0eaba8eaaea8b195e315f869c94199d1/966d8/MQTT_Dash_Android_01.webp 920w", "/en/static/0eaba8eaaea8b195e315f869c94199d1/445df/MQTT_Dash_Android_01.webp 1380w", "/en/static/0eaba8eaaea8b195e315f869c94199d1/78de1/MQTT_Dash_Android_01.webp 1840w", "/en/static/0eaba8eaaea8b195e315f869c94199d1/8df42/MQTT_Dash_Android_01.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0eaba8eaaea8b195e315f869c94199d1/81c8e/MQTT_Dash_Android_01.png 230w", "/en/static/0eaba8eaaea8b195e315f869c94199d1/08a84/MQTT_Dash_Android_01.png 460w", "/en/static/0eaba8eaaea8b195e315f869c94199d1/c0255/MQTT_Dash_Android_01.png 920w", "/en/static/0eaba8eaaea8b195e315f869c94199d1/b1001/MQTT_Dash_Android_01.png 1380w", "/en/static/0eaba8eaaea8b195e315f869c94199d1/161ec/MQTT_Dash_Android_01.png 1840w", "/en/static/0eaba8eaaea8b195e315f869c94199d1/a878e/MQTT_Dash_Android_01.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0eaba8eaaea8b195e315f869c94199d1/c0255/MQTT_Dash_Android_01.png",
              "alt": "INSTAR MQTT unter Android",
              "title": "INSTAR MQTT unter Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "mqtt-dash-iot-smarthome",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mqtt-dash-iot-smarthome",
        "aria-label": "mqtt dash iot smarthome permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT Dash (IoT, Smarthome)`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bc260418746d1e6f20c40c9ab3d41b78/a878e/MQTT_Dash_Android_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "20%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAZ0lEQVQY052OMQrEMBADl43tlc+wJrgKrq65j+TL+Z+OTZXWKQYhBINkjEF3p6pSRF7xqZV935l6p4RszskeReQWr7CpEq3xcOcZp0opTCkxEsAyFaCY8ZszrxA+RzNbBpEAG8CfGf9921ZhveklLQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bc260418746d1e6f20c40c9ab3d41b78/e4706/MQTT_Dash_Android_02.avif 230w", "/en/static/bc260418746d1e6f20c40c9ab3d41b78/d1af7/MQTT_Dash_Android_02.avif 460w", "/en/static/bc260418746d1e6f20c40c9ab3d41b78/7f308/MQTT_Dash_Android_02.avif 920w", "/en/static/bc260418746d1e6f20c40c9ab3d41b78/e1c99/MQTT_Dash_Android_02.avif 1380w", "/en/static/bc260418746d1e6f20c40c9ab3d41b78/76ea5/MQTT_Dash_Android_02.avif 1840w", "/en/static/bc260418746d1e6f20c40c9ab3d41b78/95b77/MQTT_Dash_Android_02.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bc260418746d1e6f20c40c9ab3d41b78/a0b58/MQTT_Dash_Android_02.webp 230w", "/en/static/bc260418746d1e6f20c40c9ab3d41b78/bc10c/MQTT_Dash_Android_02.webp 460w", "/en/static/bc260418746d1e6f20c40c9ab3d41b78/966d8/MQTT_Dash_Android_02.webp 920w", "/en/static/bc260418746d1e6f20c40c9ab3d41b78/445df/MQTT_Dash_Android_02.webp 1380w", "/en/static/bc260418746d1e6f20c40c9ab3d41b78/78de1/MQTT_Dash_Android_02.webp 1840w", "/en/static/bc260418746d1e6f20c40c9ab3d41b78/8df42/MQTT_Dash_Android_02.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bc260418746d1e6f20c40c9ab3d41b78/81c8e/MQTT_Dash_Android_02.png 230w", "/en/static/bc260418746d1e6f20c40c9ab3d41b78/08a84/MQTT_Dash_Android_02.png 460w", "/en/static/bc260418746d1e6f20c40c9ab3d41b78/c0255/MQTT_Dash_Android_02.png 920w", "/en/static/bc260418746d1e6f20c40c9ab3d41b78/b1001/MQTT_Dash_Android_02.png 1380w", "/en/static/bc260418746d1e6f20c40c9ab3d41b78/161ec/MQTT_Dash_Android_02.png 1840w", "/en/static/bc260418746d1e6f20c40c9ab3d41b78/a878e/MQTT_Dash_Android_02.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bc260418746d1e6f20c40c9ab3d41b78/c0255/MQTT_Dash_Android_02.png",
              "alt": "INSTAR MQTT unter Android",
              "title": "INSTAR MQTT unter Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once you `}<a parentName="p" {...{
        "href": "https://play.google.com/store/apps/details?id=net.routix.mqttdash"
      }}>{`downloaded`}</a>{`, installed and started the app click on the `}<strong parentName="p">{`Plus`}</strong>{` icon in the top right to add your MQTT broker - in our case, our INSTAR Full HD camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b2ac585a4aaaccf7b7ef89bbe45a8450/e1596/MQTT_Dash_Android_03.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAeJNSYD/xAAVEAEBAAAAAAAAAAAAAAAAAAAgIf/aAAgBAQABBQKL/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFRABAQAAAAAAAAAAAAAAAAAAIDH/2gAIAQEABj8Ci//EABoQAAMAAwEAAAAAAAAAAAAAAAABERAhMVH/2gAIAQEAAT8h4VpmvMVpLhRn/9oADAMBAAIAAwAAABATz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQACAgMBAAAAAAAAAAAAAAEAESExQVGBkf/aAAgBAQABPxB5gGW4j1T2awOIkhpWkuX7PhFbc//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b2ac585a4aaaccf7b7ef89bbe45a8450/e4706/MQTT_Dash_Android_03.avif 230w", "/en/static/b2ac585a4aaaccf7b7ef89bbe45a8450/d1af7/MQTT_Dash_Android_03.avif 460w", "/en/static/b2ac585a4aaaccf7b7ef89bbe45a8450/7f308/MQTT_Dash_Android_03.avif 920w", "/en/static/b2ac585a4aaaccf7b7ef89bbe45a8450/e1c99/MQTT_Dash_Android_03.avif 1380w", "/en/static/b2ac585a4aaaccf7b7ef89bbe45a8450/76ea5/MQTT_Dash_Android_03.avif 1840w", "/en/static/b2ac585a4aaaccf7b7ef89bbe45a8450/95b77/MQTT_Dash_Android_03.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b2ac585a4aaaccf7b7ef89bbe45a8450/a0b58/MQTT_Dash_Android_03.webp 230w", "/en/static/b2ac585a4aaaccf7b7ef89bbe45a8450/bc10c/MQTT_Dash_Android_03.webp 460w", "/en/static/b2ac585a4aaaccf7b7ef89bbe45a8450/966d8/MQTT_Dash_Android_03.webp 920w", "/en/static/b2ac585a4aaaccf7b7ef89bbe45a8450/445df/MQTT_Dash_Android_03.webp 1380w", "/en/static/b2ac585a4aaaccf7b7ef89bbe45a8450/78de1/MQTT_Dash_Android_03.webp 1840w", "/en/static/b2ac585a4aaaccf7b7ef89bbe45a8450/8df42/MQTT_Dash_Android_03.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b2ac585a4aaaccf7b7ef89bbe45a8450/e83b4/MQTT_Dash_Android_03.jpg 230w", "/en/static/b2ac585a4aaaccf7b7ef89bbe45a8450/e41a8/MQTT_Dash_Android_03.jpg 460w", "/en/static/b2ac585a4aaaccf7b7ef89bbe45a8450/4d836/MQTT_Dash_Android_03.jpg 920w", "/en/static/b2ac585a4aaaccf7b7ef89bbe45a8450/89b2d/MQTT_Dash_Android_03.jpg 1380w", "/en/static/b2ac585a4aaaccf7b7ef89bbe45a8450/5726f/MQTT_Dash_Android_03.jpg 1840w", "/en/static/b2ac585a4aaaccf7b7ef89bbe45a8450/e1596/MQTT_Dash_Android_03.jpg 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b2ac585a4aaaccf7b7ef89bbe45a8450/4d836/MQTT_Dash_Android_03.jpg",
              "alt": "INSTAR MQTT unter Android",
              "title": "INSTAR MQTT unter Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`First, name your broker and type in the IP address of your camera. The default port to communicate with the MQTT Broker on your camera is `}<strong parentName="p">{`1883`}</strong>{`. Add the username and password you `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`set for your broker`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4e0ad2bf69f979eeb57d84289b4ff7d2/a878e/MQTT_Dash_Android_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "19.565217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAcklEQVQY06XMTQrCMBCA0WlpScjPaCLJCJYIPYZn8v7bT/QEVRdv+6TWSggBmSZE5CfOOd6PqiJmxhiDsyp+nlnXlWVZvpJSopSC9x4Z28Zt33m0xlOVe+80M65m2EG994/WGpJzJqtyipFLCKQYiX94Abr5WvhVqTpQAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4e0ad2bf69f979eeb57d84289b4ff7d2/e4706/MQTT_Dash_Android_04.avif 230w", "/en/static/4e0ad2bf69f979eeb57d84289b4ff7d2/d1af7/MQTT_Dash_Android_04.avif 460w", "/en/static/4e0ad2bf69f979eeb57d84289b4ff7d2/7f308/MQTT_Dash_Android_04.avif 920w", "/en/static/4e0ad2bf69f979eeb57d84289b4ff7d2/e1c99/MQTT_Dash_Android_04.avif 1380w", "/en/static/4e0ad2bf69f979eeb57d84289b4ff7d2/76ea5/MQTT_Dash_Android_04.avif 1840w", "/en/static/4e0ad2bf69f979eeb57d84289b4ff7d2/95b77/MQTT_Dash_Android_04.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4e0ad2bf69f979eeb57d84289b4ff7d2/a0b58/MQTT_Dash_Android_04.webp 230w", "/en/static/4e0ad2bf69f979eeb57d84289b4ff7d2/bc10c/MQTT_Dash_Android_04.webp 460w", "/en/static/4e0ad2bf69f979eeb57d84289b4ff7d2/966d8/MQTT_Dash_Android_04.webp 920w", "/en/static/4e0ad2bf69f979eeb57d84289b4ff7d2/445df/MQTT_Dash_Android_04.webp 1380w", "/en/static/4e0ad2bf69f979eeb57d84289b4ff7d2/78de1/MQTT_Dash_Android_04.webp 1840w", "/en/static/4e0ad2bf69f979eeb57d84289b4ff7d2/8df42/MQTT_Dash_Android_04.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4e0ad2bf69f979eeb57d84289b4ff7d2/81c8e/MQTT_Dash_Android_04.png 230w", "/en/static/4e0ad2bf69f979eeb57d84289b4ff7d2/08a84/MQTT_Dash_Android_04.png 460w", "/en/static/4e0ad2bf69f979eeb57d84289b4ff7d2/c0255/MQTT_Dash_Android_04.png 920w", "/en/static/4e0ad2bf69f979eeb57d84289b4ff7d2/b1001/MQTT_Dash_Android_04.png 1380w", "/en/static/4e0ad2bf69f979eeb57d84289b4ff7d2/161ec/MQTT_Dash_Android_04.png 1840w", "/en/static/4e0ad2bf69f979eeb57d84289b4ff7d2/a878e/MQTT_Dash_Android_04.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4e0ad2bf69f979eeb57d84289b4ff7d2/c0255/MQTT_Dash_Android_04.png",
              "alt": "INSTAR MQTT unter Android",
              "title": "INSTAR MQTT unter Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once you saved your settings you will now see your new broker on the frontpage of your dashboard. Select it to continue.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/355406e7c8cf153d9aea03e0a7889de0/a878e/MQTT_Dash_Android_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA8UlEQVQoz32Sy26DMBBFQbBEbBNqYdPS78CPjkmsJIK4C9L+/3/cKm6thlJYnIVHmmPP9SRZliGSpimKokDbtnhtWzRCoK5rcM5n3GtCCJRlGXoeHcnskCR4YgzT7YZpmnA6n0FEsH3/i7Uga3F0DlyI0JPn+VKY/wjr/R6jc+i1BnUdrJQL7nVnDJ4Z+xb+98Io5LsdPocBH9crLkQ4dB2OUs44SImLMXjZEsaRq6rCu/fw44ieCEZrvP3BKIXe2pDl6siPwmEYQk5SKSitlygVso3CbEvIGIP3PoS/ChGcc+GnN18Y16ZpmnD7Fmtr8wVVGd2jxPOiXQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/355406e7c8cf153d9aea03e0a7889de0/e4706/MQTT_Dash_Android_05.avif 230w", "/en/static/355406e7c8cf153d9aea03e0a7889de0/d1af7/MQTT_Dash_Android_05.avif 460w", "/en/static/355406e7c8cf153d9aea03e0a7889de0/7f308/MQTT_Dash_Android_05.avif 920w", "/en/static/355406e7c8cf153d9aea03e0a7889de0/e1c99/MQTT_Dash_Android_05.avif 1380w", "/en/static/355406e7c8cf153d9aea03e0a7889de0/76ea5/MQTT_Dash_Android_05.avif 1840w", "/en/static/355406e7c8cf153d9aea03e0a7889de0/95b77/MQTT_Dash_Android_05.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/355406e7c8cf153d9aea03e0a7889de0/a0b58/MQTT_Dash_Android_05.webp 230w", "/en/static/355406e7c8cf153d9aea03e0a7889de0/bc10c/MQTT_Dash_Android_05.webp 460w", "/en/static/355406e7c8cf153d9aea03e0a7889de0/966d8/MQTT_Dash_Android_05.webp 920w", "/en/static/355406e7c8cf153d9aea03e0a7889de0/445df/MQTT_Dash_Android_05.webp 1380w", "/en/static/355406e7c8cf153d9aea03e0a7889de0/78de1/MQTT_Dash_Android_05.webp 1840w", "/en/static/355406e7c8cf153d9aea03e0a7889de0/8df42/MQTT_Dash_Android_05.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/355406e7c8cf153d9aea03e0a7889de0/81c8e/MQTT_Dash_Android_05.png 230w", "/en/static/355406e7c8cf153d9aea03e0a7889de0/08a84/MQTT_Dash_Android_05.png 460w", "/en/static/355406e7c8cf153d9aea03e0a7889de0/c0255/MQTT_Dash_Android_05.png 920w", "/en/static/355406e7c8cf153d9aea03e0a7889de0/b1001/MQTT_Dash_Android_05.png 1380w", "/en/static/355406e7c8cf153d9aea03e0a7889de0/161ec/MQTT_Dash_Android_05.png 1840w", "/en/static/355406e7c8cf153d9aea03e0a7889de0/a878e/MQTT_Dash_Android_05.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/355406e7c8cf153d9aea03e0a7889de0/c0255/MQTT_Dash_Android_05.png",
              "alt": "INSTAR MQTT unter Android",
              "title": "INSTAR MQTT unter Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We can now add an UI element to interact with our camera. In this first example we will choose a `}<strong parentName="p">{`Switch/Button`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c8f8821c51fd7d7475f03cf6ba030236/e1596/MQTT_Dash_Android_06.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHDq1ZAB//EABUQAQEAAAAAAAAAAAAAAAAAABBC/9oACAEBAAEFAmT/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAVEAEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAQAGPwJr/8QAGxAAAgMAAwAAAAAAAAAAAAAAAREAECExQWH/2gAIAQEAAT8hIY6io4OOUo/K/9oADAMBAAIAAwAAABCAD//EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxBMn//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQACAgMBAAAAAAAAAAAAAAEAESExQVGRwf/aAAgBAQABPxAQaum1zGvXs1ggcpKWXer+RDwIz//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c8f8821c51fd7d7475f03cf6ba030236/e4706/MQTT_Dash_Android_06.avif 230w", "/en/static/c8f8821c51fd7d7475f03cf6ba030236/d1af7/MQTT_Dash_Android_06.avif 460w", "/en/static/c8f8821c51fd7d7475f03cf6ba030236/7f308/MQTT_Dash_Android_06.avif 920w", "/en/static/c8f8821c51fd7d7475f03cf6ba030236/e1c99/MQTT_Dash_Android_06.avif 1380w", "/en/static/c8f8821c51fd7d7475f03cf6ba030236/76ea5/MQTT_Dash_Android_06.avif 1840w", "/en/static/c8f8821c51fd7d7475f03cf6ba030236/95b77/MQTT_Dash_Android_06.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c8f8821c51fd7d7475f03cf6ba030236/a0b58/MQTT_Dash_Android_06.webp 230w", "/en/static/c8f8821c51fd7d7475f03cf6ba030236/bc10c/MQTT_Dash_Android_06.webp 460w", "/en/static/c8f8821c51fd7d7475f03cf6ba030236/966d8/MQTT_Dash_Android_06.webp 920w", "/en/static/c8f8821c51fd7d7475f03cf6ba030236/445df/MQTT_Dash_Android_06.webp 1380w", "/en/static/c8f8821c51fd7d7475f03cf6ba030236/78de1/MQTT_Dash_Android_06.webp 1840w", "/en/static/c8f8821c51fd7d7475f03cf6ba030236/8df42/MQTT_Dash_Android_06.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c8f8821c51fd7d7475f03cf6ba030236/e83b4/MQTT_Dash_Android_06.jpg 230w", "/en/static/c8f8821c51fd7d7475f03cf6ba030236/e41a8/MQTT_Dash_Android_06.jpg 460w", "/en/static/c8f8821c51fd7d7475f03cf6ba030236/4d836/MQTT_Dash_Android_06.jpg 920w", "/en/static/c8f8821c51fd7d7475f03cf6ba030236/89b2d/MQTT_Dash_Android_06.jpg 1380w", "/en/static/c8f8821c51fd7d7475f03cf6ba030236/5726f/MQTT_Dash_Android_06.jpg 1840w", "/en/static/c8f8821c51fd7d7475f03cf6ba030236/e1596/MQTT_Dash_Android_06.jpg 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c8f8821c51fd7d7475f03cf6ba030236/4d836/MQTT_Dash_Android_06.jpg",
              "alt": "INSTAR MQTT unter Android",
              "title": "INSTAR MQTT unter Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`After naming your switch, you have to add the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`MQTT Topic`}</a>{` that you want to update every time the switch is used. In this case we choose to activate/deactivate the `}<strong parentName="p">{`Alarm Area 1`}</strong>{` of our camera. The corresponding `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`MQTT Topic`}</a>{` for this function is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/area1/enable`}</code>{`. To access this topic on our broker we have to add the keyword `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar`}</code>{` and the `}<strong parentName="p">{`LAN MAC Address`}</strong>{` of our camera - in my case this is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`000389888811`}</code>{` (you can find your cameras LAN MAC address in the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/System/Info/"
      }}>{`Web User Interface`}</a>{`).`}</p>
    <p>{`Now we have to `}<strong parentName="p">{`subscribe`}</strong>{` to the `}<strong parentName="p">{`Status Topic`}</strong>{` and `}<strong parentName="p">{`publish`}</strong>{` Updates to the `}<strong parentName="p">{`Command Topic`}</strong>{` (every time an topic is updated and the function on our camera triggered we receive an status update from MQTT broker to see that everything had worked.) The resulting topics look the following:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Command Topic`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/000389888811/alarm/area1/enable`}</code>{` (`}<em parentName="li">{`for publishing`}</em>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Status Topic`}</strong>{`: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/000389888811/status/alarm/area1/enable`}</code>{` (`}<em parentName="li">{`for subscriptions`}</em>{`)`}</li>
    </ul>
    <p>{`In some cases we have to use a different `}<strong parentName="p">{`Command Topic`}</strong>{` that allows us to send the message payload raw, without the JSON formating. So we are just sending `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` as the message payload instead of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{`. For this we have to add `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`raw`}</code>{` to the topic that we want to update:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Command Topic`}</strong>{` (RAW): `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/000389888811/alarm/area1/enable/raw`}</code>{` (`}<em parentName="li">{`for publishing`}</em>{`)`}</li>
    </ul>
    <p>{`The switch now can have 2 states - the area 1 is either active or not. You can check what values are available for a specific MQTT topic in our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`MQTT API documentation`}</a>{`. The topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/area1/enable`}</code>{` can either be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"0"}`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{`. We have to assign the first one to the `}<em parentName="p">{`OFF State`}</em>{` of our switch and the second to the `}<em parentName="p">{`ON State`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7d5fac20680e150f3d3020651682876b/a878e/MQTT_Dash_Android_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.782608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA0klEQVQY05WP2WqEQBBFO4pLL2Pbo+0W4kQMPg0YXL5BkGG+YX4u33lDF0wwkJc8HKqK4t66xaSUeGtbaK3BGPs3nuchSRL4vo8gCMDyPMf75QJrLYwxaJoGaZoiyzLUdU2H3K6qKiilSHQkDENwzqk6WFcU+OIcn+czhusV99uNTMdxxL7vZLQsC7ZtI2MniuP4F1EU/fTstSjwMAYf1qLtOqzrSiZ932OeZ5RliWEYME0TpX6KXX1ynJlUCi9aQ5xOkEKAC0GvCSGIY+84pvmLb9sqdvTR4tvyAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7d5fac20680e150f3d3020651682876b/e4706/MQTT_Dash_Android_07.avif 230w", "/en/static/7d5fac20680e150f3d3020651682876b/d1af7/MQTT_Dash_Android_07.avif 460w", "/en/static/7d5fac20680e150f3d3020651682876b/7f308/MQTT_Dash_Android_07.avif 920w", "/en/static/7d5fac20680e150f3d3020651682876b/e1c99/MQTT_Dash_Android_07.avif 1380w", "/en/static/7d5fac20680e150f3d3020651682876b/76ea5/MQTT_Dash_Android_07.avif 1840w", "/en/static/7d5fac20680e150f3d3020651682876b/95b77/MQTT_Dash_Android_07.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7d5fac20680e150f3d3020651682876b/a0b58/MQTT_Dash_Android_07.webp 230w", "/en/static/7d5fac20680e150f3d3020651682876b/bc10c/MQTT_Dash_Android_07.webp 460w", "/en/static/7d5fac20680e150f3d3020651682876b/966d8/MQTT_Dash_Android_07.webp 920w", "/en/static/7d5fac20680e150f3d3020651682876b/445df/MQTT_Dash_Android_07.webp 1380w", "/en/static/7d5fac20680e150f3d3020651682876b/78de1/MQTT_Dash_Android_07.webp 1840w", "/en/static/7d5fac20680e150f3d3020651682876b/8df42/MQTT_Dash_Android_07.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7d5fac20680e150f3d3020651682876b/81c8e/MQTT_Dash_Android_07.png 230w", "/en/static/7d5fac20680e150f3d3020651682876b/08a84/MQTT_Dash_Android_07.png 460w", "/en/static/7d5fac20680e150f3d3020651682876b/c0255/MQTT_Dash_Android_07.png 920w", "/en/static/7d5fac20680e150f3d3020651682876b/b1001/MQTT_Dash_Android_07.png 1380w", "/en/static/7d5fac20680e150f3d3020651682876b/161ec/MQTT_Dash_Android_07.png 1840w", "/en/static/7d5fac20680e150f3d3020651682876b/a878e/MQTT_Dash_Android_07.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7d5fac20680e150f3d3020651682876b/c0255/MQTT_Dash_Android_07.png",
              "alt": "INSTAR MQTT unter Android",
              "title": "INSTAR MQTT unter Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Save those settings and repeat it for all 4 areas (Tip: press & hold the switch for area 1 to clone it 3 times. Now you just have to edit each clone and replace every `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`area1`}</code>{` with the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`area2`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`area3`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`area4`}</code>{`, respectively).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1cd9b1e7af55b060cd54abc757b799ad/a878e/MQTT_Dash_Android_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAABfElEQVQoz52TyW7CMBCGDWSzIGyKQCIJAbVST72wSJzi4AQIBUTCoar6/u/xV56SqqQUqh4+ebx9M3YcZhgG6vU6NE0DY+xfKEelUkG1WgWzOEfdtmGaJk1wzqHrOvXLsUpabFQUsRovxphjWQg1DbZlod3tYjgcotFooNfrwfM8WJaFfr8P13UpodpUq9UuKMZI2OYcz4aBBudotlokUhLbtuE4DsWdTofiQlimkJFQ03Uw04R+XjwYDLBer4nFYoHpdPrFbDbDZDIh5vM5Va7u8Hu1jDKcs6jJ8XiMPM+x3W5JGicJkhJqbJOmdD0/hBcdxjAKAmTHI17SFHK5hIyiz7YgirCMIqySBL7v/y7UzsIn38d7luHteMQ+jrEJQ6RCXLARAnsp8ei69yt8CAK8Zhny3Q5rVck1hMA2jjHyvD8ceTRCfjrRPS2lhJSS2jKr1er2kQthEAQ4HA4QYYhIiKsIIRDHMb3Tm0L1xdVf0Ww26R3eQ60tP/QP6Bs0iXTPHQcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1cd9b1e7af55b060cd54abc757b799ad/e4706/MQTT_Dash_Android_08.avif 230w", "/en/static/1cd9b1e7af55b060cd54abc757b799ad/d1af7/MQTT_Dash_Android_08.avif 460w", "/en/static/1cd9b1e7af55b060cd54abc757b799ad/7f308/MQTT_Dash_Android_08.avif 920w", "/en/static/1cd9b1e7af55b060cd54abc757b799ad/e1c99/MQTT_Dash_Android_08.avif 1380w", "/en/static/1cd9b1e7af55b060cd54abc757b799ad/76ea5/MQTT_Dash_Android_08.avif 1840w", "/en/static/1cd9b1e7af55b060cd54abc757b799ad/95b77/MQTT_Dash_Android_08.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1cd9b1e7af55b060cd54abc757b799ad/a0b58/MQTT_Dash_Android_08.webp 230w", "/en/static/1cd9b1e7af55b060cd54abc757b799ad/bc10c/MQTT_Dash_Android_08.webp 460w", "/en/static/1cd9b1e7af55b060cd54abc757b799ad/966d8/MQTT_Dash_Android_08.webp 920w", "/en/static/1cd9b1e7af55b060cd54abc757b799ad/445df/MQTT_Dash_Android_08.webp 1380w", "/en/static/1cd9b1e7af55b060cd54abc757b799ad/78de1/MQTT_Dash_Android_08.webp 1840w", "/en/static/1cd9b1e7af55b060cd54abc757b799ad/8df42/MQTT_Dash_Android_08.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1cd9b1e7af55b060cd54abc757b799ad/81c8e/MQTT_Dash_Android_08.png 230w", "/en/static/1cd9b1e7af55b060cd54abc757b799ad/08a84/MQTT_Dash_Android_08.png 460w", "/en/static/1cd9b1e7af55b060cd54abc757b799ad/c0255/MQTT_Dash_Android_08.png 920w", "/en/static/1cd9b1e7af55b060cd54abc757b799ad/b1001/MQTT_Dash_Android_08.png 1380w", "/en/static/1cd9b1e7af55b060cd54abc757b799ad/161ec/MQTT_Dash_Android_08.png 1840w", "/en/static/1cd9b1e7af55b060cd54abc757b799ad/a878e/MQTT_Dash_Android_08.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1cd9b1e7af55b060cd54abc757b799ad/c0255/MQTT_Dash_Android_08.png",
              "alt": "INSTAR MQTT unter Android",
              "title": "INSTAR MQTT unter Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To show a different UI element we now want to add the alarm detection area `}<strong parentName="p">{`Sensitivity`}</strong>{` to our UI. For this click on `}<strong parentName="p">{`Plus`}</strong>{` and select `}<strong parentName="p">{`Range/progress`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/26a8b8a77245e7d3bb4a355d8ec77005/e1596/MQTT_Dash_Android_09.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABw5qQD//EABUQAQEAAAAAAAAAAAAAAAAAABEg/9oACAEBAAEFAiv/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAZEAEAAwEBAAAAAAAAAAAAAAABEBEhAEH/2gAIAQEAAT8hog71EOVvk//aAAwDAQACAAMAAAAQ0A//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAEAAwEBAQAAAAAAAAAAAAABABExIRCB/9oACAEBAAE/EFALJoRJx+kzhFQW6GWur5//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/26a8b8a77245e7d3bb4a355d8ec77005/e4706/MQTT_Dash_Android_09.avif 230w", "/en/static/26a8b8a77245e7d3bb4a355d8ec77005/d1af7/MQTT_Dash_Android_09.avif 460w", "/en/static/26a8b8a77245e7d3bb4a355d8ec77005/7f308/MQTT_Dash_Android_09.avif 920w", "/en/static/26a8b8a77245e7d3bb4a355d8ec77005/e1c99/MQTT_Dash_Android_09.avif 1380w", "/en/static/26a8b8a77245e7d3bb4a355d8ec77005/76ea5/MQTT_Dash_Android_09.avif 1840w", "/en/static/26a8b8a77245e7d3bb4a355d8ec77005/95b77/MQTT_Dash_Android_09.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/26a8b8a77245e7d3bb4a355d8ec77005/a0b58/MQTT_Dash_Android_09.webp 230w", "/en/static/26a8b8a77245e7d3bb4a355d8ec77005/bc10c/MQTT_Dash_Android_09.webp 460w", "/en/static/26a8b8a77245e7d3bb4a355d8ec77005/966d8/MQTT_Dash_Android_09.webp 920w", "/en/static/26a8b8a77245e7d3bb4a355d8ec77005/445df/MQTT_Dash_Android_09.webp 1380w", "/en/static/26a8b8a77245e7d3bb4a355d8ec77005/78de1/MQTT_Dash_Android_09.webp 1840w", "/en/static/26a8b8a77245e7d3bb4a355d8ec77005/8df42/MQTT_Dash_Android_09.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/26a8b8a77245e7d3bb4a355d8ec77005/e83b4/MQTT_Dash_Android_09.jpg 230w", "/en/static/26a8b8a77245e7d3bb4a355d8ec77005/e41a8/MQTT_Dash_Android_09.jpg 460w", "/en/static/26a8b8a77245e7d3bb4a355d8ec77005/4d836/MQTT_Dash_Android_09.jpg 920w", "/en/static/26a8b8a77245e7d3bb4a355d8ec77005/89b2d/MQTT_Dash_Android_09.jpg 1380w", "/en/static/26a8b8a77245e7d3bb4a355d8ec77005/5726f/MQTT_Dash_Android_09.jpg 1840w", "/en/static/26a8b8a77245e7d3bb4a355d8ec77005/e1596/MQTT_Dash_Android_09.jpg 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/26a8b8a77245e7d3bb4a355d8ec77005/4d836/MQTT_Dash_Android_09.jpg",
              "alt": "INSTAR MQTT unter Android",
              "title": "INSTAR MQTT unter Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The sensitivity can be accessed by the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`MQTT Topic`}</a>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/area1/sensitivity`}</code>{`. The value here can be between `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"100"}`}</code>{`. To extract the number from this JSON expression we have to define the `}<strong parentName="p">{`JSON Path`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`$.val`}</code>{`. Now add the minimal (1) and maximal (100) value and make sure that precision is set to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{`!`}</p>
    <p>{`Note that we are using the Status Topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/000389888811/status/alarm/area1/sensitivity`}</code>{`, that will give use the current state in the JSON format. For the Command Topic we have to use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/000389888811/alarm/area1/sensitivity/raw`}</code>{` - the `}<strong parentName="p">{`raw`}</strong>{` at the end means that we are able to send our data non-JSON formated.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e40230c049b4a025ce85e2dd6f3046a0/a878e/MQTT_Dash_Android_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.217391304347824%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA/klEQVQY05WOu2rDQBBFFxkXXmmtF9K+JEsgRwQcFAdvuhSpXBgc8gX5uXzoCdp06VJc7plhuHOFlJLucCDPc4QQ/9YmSdjv92w2CdvtFlHXNeMw0LQtRVHgnIteVRXW2vioaRq01hRFjrEO4zrqqsRYH1m3Ldb3GOcRvdZ873aEsuThdOLjfsd7z7Is3G43jDGEELher3jvmI4zJnzGoIfHJ4Z5wbYVx/Mb+vSOsFrzVZbMTUM/jryGEJtN08TlconN5nnmfH6J4V3fY47PtMZy6Ds67+LNMI64fkCkWUaS50ilSNOUnZRkWRZZ/uHV11mlkmxlpX617pRCZSk/cbR+DgNXPrUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e40230c049b4a025ce85e2dd6f3046a0/e4706/MQTT_Dash_Android_10.avif 230w", "/en/static/e40230c049b4a025ce85e2dd6f3046a0/d1af7/MQTT_Dash_Android_10.avif 460w", "/en/static/e40230c049b4a025ce85e2dd6f3046a0/7f308/MQTT_Dash_Android_10.avif 920w", "/en/static/e40230c049b4a025ce85e2dd6f3046a0/e1c99/MQTT_Dash_Android_10.avif 1380w", "/en/static/e40230c049b4a025ce85e2dd6f3046a0/76ea5/MQTT_Dash_Android_10.avif 1840w", "/en/static/e40230c049b4a025ce85e2dd6f3046a0/95b77/MQTT_Dash_Android_10.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e40230c049b4a025ce85e2dd6f3046a0/a0b58/MQTT_Dash_Android_10.webp 230w", "/en/static/e40230c049b4a025ce85e2dd6f3046a0/bc10c/MQTT_Dash_Android_10.webp 460w", "/en/static/e40230c049b4a025ce85e2dd6f3046a0/966d8/MQTT_Dash_Android_10.webp 920w", "/en/static/e40230c049b4a025ce85e2dd6f3046a0/445df/MQTT_Dash_Android_10.webp 1380w", "/en/static/e40230c049b4a025ce85e2dd6f3046a0/78de1/MQTT_Dash_Android_10.webp 1840w", "/en/static/e40230c049b4a025ce85e2dd6f3046a0/8df42/MQTT_Dash_Android_10.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e40230c049b4a025ce85e2dd6f3046a0/81c8e/MQTT_Dash_Android_10.png 230w", "/en/static/e40230c049b4a025ce85e2dd6f3046a0/08a84/MQTT_Dash_Android_10.png 460w", "/en/static/e40230c049b4a025ce85e2dd6f3046a0/c0255/MQTT_Dash_Android_10.png 920w", "/en/static/e40230c049b4a025ce85e2dd6f3046a0/b1001/MQTT_Dash_Android_10.png 1380w", "/en/static/e40230c049b4a025ce85e2dd6f3046a0/161ec/MQTT_Dash_Android_10.png 1840w", "/en/static/e40230c049b4a025ce85e2dd6f3046a0/a878e/MQTT_Dash_Android_10.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e40230c049b4a025ce85e2dd6f3046a0/c0255/MQTT_Dash_Android_10.png",
              "alt": "INSTAR MQTT unter Android",
              "title": "INSTAR MQTT unter Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In a last step we can now add our cameras live image to our dashboard.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4cd9c2d45cd8fcc4276191e5bf40f3ec/a878e/MQTT_Dash_Android_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA8ElEQVQoz32PW26DMBREzWsFqQwF0p8uIUhARRQ/cA0B8ch397+Nqa5VqrSk/TiyPNIcj1kYhiCiKILneeCco+97jOMIIQSqqkJd1zuapkGapq5DXYI8zPd9EEEQgDGGJEkwzzO6roMxBlrrHUopWGuR57nrUHfzMLpsOGEcY5omB5WllDtoOT2WZ5nr0LJvx29hTAuXBcu64t1aCJIo9QPKWmOQ3S38U/gcx7jRwmFAK8RD9OWCTmsct4WPhOGX8IVzfKwrxrbFVUoMSu2gfDEGr/8Jt4VPhwPE+YyqKFAWhTvvoaw8nfBWlkg43335E834xyxc8cMqAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4cd9c2d45cd8fcc4276191e5bf40f3ec/e4706/MQTT_Dash_Android_11.avif 230w", "/en/static/4cd9c2d45cd8fcc4276191e5bf40f3ec/d1af7/MQTT_Dash_Android_11.avif 460w", "/en/static/4cd9c2d45cd8fcc4276191e5bf40f3ec/7f308/MQTT_Dash_Android_11.avif 920w", "/en/static/4cd9c2d45cd8fcc4276191e5bf40f3ec/e1c99/MQTT_Dash_Android_11.avif 1380w", "/en/static/4cd9c2d45cd8fcc4276191e5bf40f3ec/76ea5/MQTT_Dash_Android_11.avif 1840w", "/en/static/4cd9c2d45cd8fcc4276191e5bf40f3ec/95b77/MQTT_Dash_Android_11.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4cd9c2d45cd8fcc4276191e5bf40f3ec/a0b58/MQTT_Dash_Android_11.webp 230w", "/en/static/4cd9c2d45cd8fcc4276191e5bf40f3ec/bc10c/MQTT_Dash_Android_11.webp 460w", "/en/static/4cd9c2d45cd8fcc4276191e5bf40f3ec/966d8/MQTT_Dash_Android_11.webp 920w", "/en/static/4cd9c2d45cd8fcc4276191e5bf40f3ec/445df/MQTT_Dash_Android_11.webp 1380w", "/en/static/4cd9c2d45cd8fcc4276191e5bf40f3ec/78de1/MQTT_Dash_Android_11.webp 1840w", "/en/static/4cd9c2d45cd8fcc4276191e5bf40f3ec/8df42/MQTT_Dash_Android_11.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4cd9c2d45cd8fcc4276191e5bf40f3ec/81c8e/MQTT_Dash_Android_11.png 230w", "/en/static/4cd9c2d45cd8fcc4276191e5bf40f3ec/08a84/MQTT_Dash_Android_11.png 460w", "/en/static/4cd9c2d45cd8fcc4276191e5bf40f3ec/c0255/MQTT_Dash_Android_11.png 920w", "/en/static/4cd9c2d45cd8fcc4276191e5bf40f3ec/b1001/MQTT_Dash_Android_11.png 1380w", "/en/static/4cd9c2d45cd8fcc4276191e5bf40f3ec/161ec/MQTT_Dash_Android_11.png 1840w", "/en/static/4cd9c2d45cd8fcc4276191e5bf40f3ec/a878e/MQTT_Dash_Android_11.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4cd9c2d45cd8fcc4276191e5bf40f3ec/c0255/MQTT_Dash_Android_11.png",
              "alt": "INSTAR MQTT unter Android",
              "title": "INSTAR MQTT unter Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here we simply have to add our `}<a parentName="p" {...{
        "href": "/en/1080p_Series_CGI_List/"
      }}>{`cameras snapshot path`}</a>{` (just add your IP address and camera login):`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://IP-Address:Port/tmpfs/snap.jpg?usr=admin&pwd=instar`}</code>{` Snapshot (1080p)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://IP-Address:Port/tmpfs/auto.jpg?usr=admin&pwd=instar`}</code>{` Snapshot (320p)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://IP-Address:Port/tmpfs/auto2.jpg?usr=admin&pwd=instar`}</code>{` Snapshot (160p)`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3c1b30cf505cfcbd932e78930d9b55f9/e1596/MQTT_Dash_Android_12.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHFUyCoB//EABYQAAMAAAAAAAAAAAAAAAAAABEgIf/aAAgBAQABBQKBf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABoQAAEFAQAAAAAAAAAAAAAAAAABEBEhQTH/2gAIAQEAAT8hjKyGzoqN/9oADAMBAAIAAwAAABBgD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAAIDAAMAAAAAAAAAAAAAAAERACExEFGB/9oACAEBAAE/EGATYNaiQVkAY1FdOF1g+cf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3c1b30cf505cfcbd932e78930d9b55f9/e4706/MQTT_Dash_Android_12.avif 230w", "/en/static/3c1b30cf505cfcbd932e78930d9b55f9/d1af7/MQTT_Dash_Android_12.avif 460w", "/en/static/3c1b30cf505cfcbd932e78930d9b55f9/7f308/MQTT_Dash_Android_12.avif 920w", "/en/static/3c1b30cf505cfcbd932e78930d9b55f9/e1c99/MQTT_Dash_Android_12.avif 1380w", "/en/static/3c1b30cf505cfcbd932e78930d9b55f9/76ea5/MQTT_Dash_Android_12.avif 1840w", "/en/static/3c1b30cf505cfcbd932e78930d9b55f9/95b77/MQTT_Dash_Android_12.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3c1b30cf505cfcbd932e78930d9b55f9/a0b58/MQTT_Dash_Android_12.webp 230w", "/en/static/3c1b30cf505cfcbd932e78930d9b55f9/bc10c/MQTT_Dash_Android_12.webp 460w", "/en/static/3c1b30cf505cfcbd932e78930d9b55f9/966d8/MQTT_Dash_Android_12.webp 920w", "/en/static/3c1b30cf505cfcbd932e78930d9b55f9/445df/MQTT_Dash_Android_12.webp 1380w", "/en/static/3c1b30cf505cfcbd932e78930d9b55f9/78de1/MQTT_Dash_Android_12.webp 1840w", "/en/static/3c1b30cf505cfcbd932e78930d9b55f9/8df42/MQTT_Dash_Android_12.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3c1b30cf505cfcbd932e78930d9b55f9/e83b4/MQTT_Dash_Android_12.jpg 230w", "/en/static/3c1b30cf505cfcbd932e78930d9b55f9/e41a8/MQTT_Dash_Android_12.jpg 460w", "/en/static/3c1b30cf505cfcbd932e78930d9b55f9/4d836/MQTT_Dash_Android_12.jpg 920w", "/en/static/3c1b30cf505cfcbd932e78930d9b55f9/89b2d/MQTT_Dash_Android_12.jpg 1380w", "/en/static/3c1b30cf505cfcbd932e78930d9b55f9/5726f/MQTT_Dash_Android_12.jpg 1840w", "/en/static/3c1b30cf505cfcbd932e78930d9b55f9/e1596/MQTT_Dash_Android_12.jpg 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3c1b30cf505cfcbd932e78930d9b55f9/4d836/MQTT_Dash_Android_12.jpg",
              "alt": "INSTAR MQTT unter Android",
              "title": "INSTAR MQTT unter Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Hier müssen wir nur unseren den `}<a parentName="p" {...{
        "href": "/en/1080p_Series_CGI_List/"
      }}>{`Snapshot Pfad`}</a>{` unserer Kamera hinzufügen (fügen Sie einfach Ihre IP-Adresse und Ihren Kamera-Login hinzu):`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://IP-Address:Port/tmpfs/snap.jpg?usr=admin&pwd=instar`}</code>{` Snapshot (1080p)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://IP-Address:Port/tmpfs/auto.jpg?usr=admin&pwd=instar`}</code>{` Snapshot (320p)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://IP-Address:Port/tmpfs/auto2.jpg?usr=admin&pwd=instar`}</code>{` Snapshot (160p)`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0adcc5fa1c5e0bd108f080d2f1ea5edb/a878e/MQTT_Dash_Android_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABI0lEQVQoz6WOO2/CMBSFndA0dgakEvzOi+CQxB0iVWXowA4SEhn7///IqWIYS5cOn/z5SvfcQ9brNdq2RZ7nIIQEoih66qtVjJeYII5jxHGE9DXBZrMBTRNkNAXhnGMYBiilAuM4YplZa9H3fThUVRW6rgPfblHWO9j9O6TgqHYOtumgxBblfoRpepCiKHC73eC9D2HzPIewaZpwvV7DkePxiPP5AmsN2r2D+pwhTQnXj6idhxY53PQF6U8gSir40WMJ1lqHVkKI8D8cDqHt0tA5F1xrA1U04ELAGA2tZHBrNJSSILkSKGoDqTkoy5CmKRhjoJT+6gsZo3fPMjCW3WfZ/SV1y/F9mdANFiR5BXssL9An/hdEyDecPjzKpkDyWPwPP/xOrOitE8+rAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0adcc5fa1c5e0bd108f080d2f1ea5edb/e4706/MQTT_Dash_Android_13.avif 230w", "/en/static/0adcc5fa1c5e0bd108f080d2f1ea5edb/d1af7/MQTT_Dash_Android_13.avif 460w", "/en/static/0adcc5fa1c5e0bd108f080d2f1ea5edb/7f308/MQTT_Dash_Android_13.avif 920w", "/en/static/0adcc5fa1c5e0bd108f080d2f1ea5edb/e1c99/MQTT_Dash_Android_13.avif 1380w", "/en/static/0adcc5fa1c5e0bd108f080d2f1ea5edb/76ea5/MQTT_Dash_Android_13.avif 1840w", "/en/static/0adcc5fa1c5e0bd108f080d2f1ea5edb/95b77/MQTT_Dash_Android_13.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0adcc5fa1c5e0bd108f080d2f1ea5edb/a0b58/MQTT_Dash_Android_13.webp 230w", "/en/static/0adcc5fa1c5e0bd108f080d2f1ea5edb/bc10c/MQTT_Dash_Android_13.webp 460w", "/en/static/0adcc5fa1c5e0bd108f080d2f1ea5edb/966d8/MQTT_Dash_Android_13.webp 920w", "/en/static/0adcc5fa1c5e0bd108f080d2f1ea5edb/445df/MQTT_Dash_Android_13.webp 1380w", "/en/static/0adcc5fa1c5e0bd108f080d2f1ea5edb/78de1/MQTT_Dash_Android_13.webp 1840w", "/en/static/0adcc5fa1c5e0bd108f080d2f1ea5edb/8df42/MQTT_Dash_Android_13.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0adcc5fa1c5e0bd108f080d2f1ea5edb/81c8e/MQTT_Dash_Android_13.png 230w", "/en/static/0adcc5fa1c5e0bd108f080d2f1ea5edb/08a84/MQTT_Dash_Android_13.png 460w", "/en/static/0adcc5fa1c5e0bd108f080d2f1ea5edb/c0255/MQTT_Dash_Android_13.png 920w", "/en/static/0adcc5fa1c5e0bd108f080d2f1ea5edb/b1001/MQTT_Dash_Android_13.png 1380w", "/en/static/0adcc5fa1c5e0bd108f080d2f1ea5edb/161ec/MQTT_Dash_Android_13.png 1840w", "/en/static/0adcc5fa1c5e0bd108f080d2f1ea5edb/a878e/MQTT_Dash_Android_13.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0adcc5fa1c5e0bd108f080d2f1ea5edb/c0255/MQTT_Dash_Android_13.png",
              "alt": "INSTAR MQTT unter Android",
              "title": "INSTAR MQTT unter Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`After setting the update interval and saving your settings, you should now be able to see your cameras live image on your MQTT dashboard. If you don't like the arrangement of the UI elements on your dash, click on the lock icon in the top right. When unlocked, you are able to rearrange each element.`}</p>
    <h2 {...{
      "id": "mqtt-dashboard-iot-and-node-red-controller",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mqtt-dashboard-iot-and-node-red-controller",
        "aria-label": "mqtt dashboard iot and node red controller permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mqtt Dashboard IoT and Node-RED controller`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a6c69e6946a466c818f2b59fd82eceb5/a878e/MQTT_Dashboard_Android_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACG0lEQVQ4y6WTPW/TUBSGA3IS4o/4Xt9rxx+pQxLsfDiNlCIlTkqQYOiCulRCYkKCDgUGGNkRAxMTLIgNCcG/YGFBYuP/vOi4TuLQQisxHB352Pe555z3dalareJfUS6Xoes6kiTBeDxGo9EA5xyWZZ0bpYuAlUoFmqYhCAI0m00IIf4fqKoqGGMwTXMDK0CLF5wLvFatolapbAH/7IQ6FXkuQkt0gPZEoSgKyopCRboJV6muKGvg6hBlVdOgm0aWi/VSvV6H67rwPA/hzg6cIMABY3isaeiRAFJCK3bIObiwcCdd4vXhMzy8dx+mxWDxfGQpZabgYDDAzb099MZjPPd9vLNt3I4iBO029BxI4xmGgW4c4df3n/j24gO+Hr9Bw3bAODsF0kez2QzT6RTDJMGNbhd+FEHGMTpxjHbY2tqhsARUQ8fHt+/x4+UnfH7wahtIHU4mkyxGo1HWadLvY9iLsTsYYrcTo1YECoGaWsMyXeDR3SMcJPNMnLUoqx2SYclnYRii1QrRDPxsr5YUZ0Shb13PQ6t7HdJzYNv2RpSiyhu1C89K+Qwws4wQMC0BKeTa7BnwssYuetDkHD7nONF1OJyDFY1NBy4KAq46INsYnKNjmviiXEHAGOp5/VIdUtC/LOTpaJLGEwJNaeOJ34crJay8Tu9LjuPgb0HLJxd0Ox3cmi+QpinmaYpZmmI5X+Dp/iH20/m6Tvk3XLl75tYEuUEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a6c69e6946a466c818f2b59fd82eceb5/e4706/MQTT_Dashboard_Android_01.avif 230w", "/en/static/a6c69e6946a466c818f2b59fd82eceb5/d1af7/MQTT_Dashboard_Android_01.avif 460w", "/en/static/a6c69e6946a466c818f2b59fd82eceb5/7f308/MQTT_Dashboard_Android_01.avif 920w", "/en/static/a6c69e6946a466c818f2b59fd82eceb5/e1c99/MQTT_Dashboard_Android_01.avif 1380w", "/en/static/a6c69e6946a466c818f2b59fd82eceb5/76ea5/MQTT_Dashboard_Android_01.avif 1840w", "/en/static/a6c69e6946a466c818f2b59fd82eceb5/95b77/MQTT_Dashboard_Android_01.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a6c69e6946a466c818f2b59fd82eceb5/a0b58/MQTT_Dashboard_Android_01.webp 230w", "/en/static/a6c69e6946a466c818f2b59fd82eceb5/bc10c/MQTT_Dashboard_Android_01.webp 460w", "/en/static/a6c69e6946a466c818f2b59fd82eceb5/966d8/MQTT_Dashboard_Android_01.webp 920w", "/en/static/a6c69e6946a466c818f2b59fd82eceb5/445df/MQTT_Dashboard_Android_01.webp 1380w", "/en/static/a6c69e6946a466c818f2b59fd82eceb5/78de1/MQTT_Dashboard_Android_01.webp 1840w", "/en/static/a6c69e6946a466c818f2b59fd82eceb5/8df42/MQTT_Dashboard_Android_01.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a6c69e6946a466c818f2b59fd82eceb5/81c8e/MQTT_Dashboard_Android_01.png 230w", "/en/static/a6c69e6946a466c818f2b59fd82eceb5/08a84/MQTT_Dashboard_Android_01.png 460w", "/en/static/a6c69e6946a466c818f2b59fd82eceb5/c0255/MQTT_Dashboard_Android_01.png 920w", "/en/static/a6c69e6946a466c818f2b59fd82eceb5/b1001/MQTT_Dashboard_Android_01.png 1380w", "/en/static/a6c69e6946a466c818f2b59fd82eceb5/161ec/MQTT_Dashboard_Android_01.png 1840w", "/en/static/a6c69e6946a466c818f2b59fd82eceb5/a878e/MQTT_Dashboard_Android_01.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a6c69e6946a466c818f2b59fd82eceb5/c0255/MQTT_Dashboard_Android_01.png",
              "alt": "INSTAR MQTT unter Android",
              "title": "INSTAR MQTT unter Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`As mentioned above, the functions as well as the UI of all those MQTT apps is very similar. Just click to add your broker.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c5a7ac91d12c73960eb0d84916e2a044/a878e/MQTT_Dashboard_Android_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACJklEQVQ4y41Sy27TUBA1iIamSez7sp2b2HEebRLlIVWAGjehlSIoG1aVQAKJDQsWsAGJih38AmwRK9jzCyz4sYNmHFtJGhCL0b2eO3N85pxx+v0+hsMhxuMxjDGQUkIpxWce9C2EQLPZxGg0wnQ6xWQy2ajPwxkMBlzQ6/U2HraDGn3fZ9BWq4U4jqG1vkbAoQcCC8OQE1S0CzBv9jyviHWwgiH9MQfZpv8/cW3kTqeDJEkQRRFrQuC7QmkFSU1brEz+ngNaa1kXGnnnqCoDssaHUSvN1ozaL5dRrhwUEjDDbrdbaLQd1CykRGRCBGpzC8ikh8sHOJsvEARBxpDG5AIh/qmVJ0QB5huDW6U9fHjzDr8//sCvq2/4/uUrasLLTGk0GlCB4aa/uRs1m8XeaaVxUK1gdnwP75cv8Hb5HJcXj1ETbgbYbrcxiTqI6jYzZgdD0np976iOdEvaCeIkRqVa5XeH2PWPjvDs8AR3hmOE1qJKIFsuSyXZHAIkMDLR1uuwUQQbx2hYyzlmSCsj6j5qrssNx0IiWLnLbkqJxK8j1GbDsJqU6HoeDl2P72Qgm0JuBcZAKIVASvy86WDiuqjIbO9Yt7U7s5QSN7TGVfk2Pu2X+E45R62WMlteDV9rfLYDDEwAj4qKxd6UINAGJS3x0k/w2k+wpyXnnEV6ijRNcZqmxflqeYnz+X3M0lmRP0vnOE/na3WnuJue4Mn8Ak8Xj/hOuT/GfYdnJIZBVwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c5a7ac91d12c73960eb0d84916e2a044/e4706/MQTT_Dashboard_Android_02.avif 230w", "/en/static/c5a7ac91d12c73960eb0d84916e2a044/d1af7/MQTT_Dashboard_Android_02.avif 460w", "/en/static/c5a7ac91d12c73960eb0d84916e2a044/7f308/MQTT_Dashboard_Android_02.avif 920w", "/en/static/c5a7ac91d12c73960eb0d84916e2a044/e1c99/MQTT_Dashboard_Android_02.avif 1380w", "/en/static/c5a7ac91d12c73960eb0d84916e2a044/76ea5/MQTT_Dashboard_Android_02.avif 1840w", "/en/static/c5a7ac91d12c73960eb0d84916e2a044/95b77/MQTT_Dashboard_Android_02.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c5a7ac91d12c73960eb0d84916e2a044/a0b58/MQTT_Dashboard_Android_02.webp 230w", "/en/static/c5a7ac91d12c73960eb0d84916e2a044/bc10c/MQTT_Dashboard_Android_02.webp 460w", "/en/static/c5a7ac91d12c73960eb0d84916e2a044/966d8/MQTT_Dashboard_Android_02.webp 920w", "/en/static/c5a7ac91d12c73960eb0d84916e2a044/445df/MQTT_Dashboard_Android_02.webp 1380w", "/en/static/c5a7ac91d12c73960eb0d84916e2a044/78de1/MQTT_Dashboard_Android_02.webp 1840w", "/en/static/c5a7ac91d12c73960eb0d84916e2a044/8df42/MQTT_Dashboard_Android_02.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c5a7ac91d12c73960eb0d84916e2a044/81c8e/MQTT_Dashboard_Android_02.png 230w", "/en/static/c5a7ac91d12c73960eb0d84916e2a044/08a84/MQTT_Dashboard_Android_02.png 460w", "/en/static/c5a7ac91d12c73960eb0d84916e2a044/c0255/MQTT_Dashboard_Android_02.png 920w", "/en/static/c5a7ac91d12c73960eb0d84916e2a044/b1001/MQTT_Dashboard_Android_02.png 1380w", "/en/static/c5a7ac91d12c73960eb0d84916e2a044/161ec/MQTT_Dashboard_Android_02.png 1840w", "/en/static/c5a7ac91d12c73960eb0d84916e2a044/a878e/MQTT_Dashboard_Android_02.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c5a7ac91d12c73960eb0d84916e2a044/c0255/MQTT_Dashboard_Android_02.png",
              "alt": "INSTAR MQTT unter Android",
              "title": "INSTAR MQTT unter Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add your cameras MQTT service.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/70431e82c42f0e222e975d9eea63c0fc/a878e/MQTT_Dashboard_Android_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAABq0lEQVQ4y4WTbXOiMBSFmakJBNB11JqC7AdJomjLy8BoZ7qznen//1Fn52aFplTthwe4ycnh5M2bzWZYLpdYrVZYLBbgnFt837fQdxRFto80hBDii8bFo0cQBANjYV+7mmtGg2GfyE12r/4J797f7uEmdpN7boJradyp50qhrCoUhwPyPIdSaoBqWmtvPOiWIeMcB6Xwt+vw1nUwWsMYM6C1/jQMwxDF8YiXqoI2BuqCvgjpJEwYwyHP8adpUBeFTdUb0Ztqa0h/nkYRPs5nvJ9OOJUlzlWF17JEW5Y4Pj9jPp+DMYZcazRti6quoS5G2nkPCSMhsM8ymCyDSlPkaQqVJMikxFOSII5ja7jf79F1HZqmsQYuQ8J+7R4Ys9Nivo9oOkUYx9/Wkwa2bYu6rm2q3W43QPUXQxEE9gaEQtgOgmqi12Q0C2Ow3W6x2Wxs3UM17YV3b4fHu03T7rFt1w72t4HcxwNnmNwY0AeYcGZ1pL95UwI/APM5kugXliK238H4hlw0CxFb3X/N5/32nqSEdFit1yjkb+Ryg0e5tm1jDbVvZWp1pHf7/gEgZGTdQD+WewAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/70431e82c42f0e222e975d9eea63c0fc/e4706/MQTT_Dashboard_Android_03.avif 230w", "/en/static/70431e82c42f0e222e975d9eea63c0fc/d1af7/MQTT_Dashboard_Android_03.avif 460w", "/en/static/70431e82c42f0e222e975d9eea63c0fc/7f308/MQTT_Dashboard_Android_03.avif 920w", "/en/static/70431e82c42f0e222e975d9eea63c0fc/e1c99/MQTT_Dashboard_Android_03.avif 1380w", "/en/static/70431e82c42f0e222e975d9eea63c0fc/76ea5/MQTT_Dashboard_Android_03.avif 1840w", "/en/static/70431e82c42f0e222e975d9eea63c0fc/95b77/MQTT_Dashboard_Android_03.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/70431e82c42f0e222e975d9eea63c0fc/a0b58/MQTT_Dashboard_Android_03.webp 230w", "/en/static/70431e82c42f0e222e975d9eea63c0fc/bc10c/MQTT_Dashboard_Android_03.webp 460w", "/en/static/70431e82c42f0e222e975d9eea63c0fc/966d8/MQTT_Dashboard_Android_03.webp 920w", "/en/static/70431e82c42f0e222e975d9eea63c0fc/445df/MQTT_Dashboard_Android_03.webp 1380w", "/en/static/70431e82c42f0e222e975d9eea63c0fc/78de1/MQTT_Dashboard_Android_03.webp 1840w", "/en/static/70431e82c42f0e222e975d9eea63c0fc/8df42/MQTT_Dashboard_Android_03.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/70431e82c42f0e222e975d9eea63c0fc/81c8e/MQTT_Dashboard_Android_03.png 230w", "/en/static/70431e82c42f0e222e975d9eea63c0fc/08a84/MQTT_Dashboard_Android_03.png 460w", "/en/static/70431e82c42f0e222e975d9eea63c0fc/c0255/MQTT_Dashboard_Android_03.png 920w", "/en/static/70431e82c42f0e222e975d9eea63c0fc/b1001/MQTT_Dashboard_Android_03.png 1380w", "/en/static/70431e82c42f0e222e975d9eea63c0fc/161ec/MQTT_Dashboard_Android_03.png 1840w", "/en/static/70431e82c42f0e222e975d9eea63c0fc/a878e/MQTT_Dashboard_Android_03.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/70431e82c42f0e222e975d9eea63c0fc/c0255/MQTT_Dashboard_Android_03.png",
              "alt": "INSTAR MQTT unter Android",
              "title": "INSTAR MQTT unter Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now add a switch (`}<strong parentName="p">{`Toogle`}</strong>{`) to switch on/off your cameras alarm areas.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f9c9cc7bacb9a68ae4bef79eff3a8ad6/a878e/MQTT_Dashboard_Android_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABsklEQVQoz32STY/aMBCGc90eNvZ4/BXHsQNJaBCQBVYQh4K6rVbqx6Hq3+it//+yItmuUljxHCI58uN3POMoz/OqqpRSAMCuAIAkSZqmWa/Xm81GSkkphX9EJjE2tUKIt19j01prjJn2aK3hfyLjMldMqtnMe5/neVEUw6bBR0Tn3G632263Wutx7Fn+XDXfF7v1YrXZbuq6ds4hIiGEUkoIkVImSbJarRaLhRDiSi6b59m6LqrmoZnkE0SUUiqlZA9yTiglcUwIgSuibOJt7qRSQ5F8BCDzMtGM0/4W78hVWWYmZYzRKwilHBjCO9qrnKapMUYphYhCiHOtShqhtDhXjxwpwC15Op3WdT2fz621AMA5F8h5fwW4SeScK8tyCBdCvE54+FJqrb1u8qhhWea9d86NxztAKfXe35Kt99ZaKWUfC8hw/MLO00K8cN4aGP1YLiXncRwPx9+RezLKuchkADGlH8j94EfPp1Oi9TBkxtg3t8ykhn55AUckDD5K+yWtYwYcMTp9OoYuDLRt+/fp99fDcR/a8zKEY+iOoTv0O7oQHsP+V/f05/Dzsd13IbwAp/JZ3OOr5ZIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f9c9cc7bacb9a68ae4bef79eff3a8ad6/e4706/MQTT_Dashboard_Android_04.avif 230w", "/en/static/f9c9cc7bacb9a68ae4bef79eff3a8ad6/d1af7/MQTT_Dashboard_Android_04.avif 460w", "/en/static/f9c9cc7bacb9a68ae4bef79eff3a8ad6/7f308/MQTT_Dashboard_Android_04.avif 920w", "/en/static/f9c9cc7bacb9a68ae4bef79eff3a8ad6/e1c99/MQTT_Dashboard_Android_04.avif 1380w", "/en/static/f9c9cc7bacb9a68ae4bef79eff3a8ad6/76ea5/MQTT_Dashboard_Android_04.avif 1840w", "/en/static/f9c9cc7bacb9a68ae4bef79eff3a8ad6/95b77/MQTT_Dashboard_Android_04.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f9c9cc7bacb9a68ae4bef79eff3a8ad6/a0b58/MQTT_Dashboard_Android_04.webp 230w", "/en/static/f9c9cc7bacb9a68ae4bef79eff3a8ad6/bc10c/MQTT_Dashboard_Android_04.webp 460w", "/en/static/f9c9cc7bacb9a68ae4bef79eff3a8ad6/966d8/MQTT_Dashboard_Android_04.webp 920w", "/en/static/f9c9cc7bacb9a68ae4bef79eff3a8ad6/445df/MQTT_Dashboard_Android_04.webp 1380w", "/en/static/f9c9cc7bacb9a68ae4bef79eff3a8ad6/78de1/MQTT_Dashboard_Android_04.webp 1840w", "/en/static/f9c9cc7bacb9a68ae4bef79eff3a8ad6/8df42/MQTT_Dashboard_Android_04.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f9c9cc7bacb9a68ae4bef79eff3a8ad6/81c8e/MQTT_Dashboard_Android_04.png 230w", "/en/static/f9c9cc7bacb9a68ae4bef79eff3a8ad6/08a84/MQTT_Dashboard_Android_04.png 460w", "/en/static/f9c9cc7bacb9a68ae4bef79eff3a8ad6/c0255/MQTT_Dashboard_Android_04.png 920w", "/en/static/f9c9cc7bacb9a68ae4bef79eff3a8ad6/b1001/MQTT_Dashboard_Android_04.png 1380w", "/en/static/f9c9cc7bacb9a68ae4bef79eff3a8ad6/161ec/MQTT_Dashboard_Android_04.png 1840w", "/en/static/f9c9cc7bacb9a68ae4bef79eff3a8ad6/a878e/MQTT_Dashboard_Android_04.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f9c9cc7bacb9a68ae4bef79eff3a8ad6/c0255/MQTT_Dashboard_Android_04.png",
              "alt": "INSTAR MQTT unter Android",
              "title": "INSTAR MQTT unter Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`MQTT Topic`}</a>{` and the available values this switch can have.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/347475bf75ca6ac16e001fdd15a2aec9/a878e/MQTT_Dashboard_Android_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAACEklEQVQoz1WS246bMBRFLVUhZBLA+IKxwdjgGGduDUMngc5oVLUPfW8/oD/R/5cqcBLRheUHpM3WOSyAECKERFEUBMH6ShAEu91OKeWcs3uLr6AFEEKAkyROks1ms14QBEEURU3TGGMYY2maIoT87b9CKVVKgQzj7Xa7rL2E47hUlW4apXVZlrKqtNacc58nhOR5DnZRtF6vV6vVMrwKAhjHvb3/cnh6nNnv903TMMYghGmaQgg55wBCeDgc2ratFpSVrHnx5/jt1+N4PB6HcazrOp9hMznLCaWAUvrS96fT6eHhwVrbzrjWiaLISsGrkjFGKSUz1EOmR2UcEEL6vn/pOrvft9bejpTS2bYUxbSqaVn/AVNYEgYExu/Pzx9d11t7NKabT2+MEUKUpRCCUuo35FvZtT5FCGSUDufz6fX1/nC41bq2FZwLIbTWflWc8yzLGGOCTjNnjGGMp5m7rnvpe+dce8U5J4Soqsr/aq11lmUXOWZTMMaMMZCm6f2M1lotyPPcv6GUXlILzyilxhjglfCShOvwJkkcx3VdSynruvZuUEx82M8vpQRLNz4Fq6Wexhgppe9EGG/ThBCCEMrznBCSIQzCK3fhRm7Tu3AThlN/kiRKKa11URQI44qJn+rpsnNCMCGWS8CucJZ/ZopP8kxWWGvfv76dz+dhGM7j8OPt4+/338MwjDPDOH4Mb/8ArI5YgwXlKA0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/347475bf75ca6ac16e001fdd15a2aec9/e4706/MQTT_Dashboard_Android_05.avif 230w", "/en/static/347475bf75ca6ac16e001fdd15a2aec9/d1af7/MQTT_Dashboard_Android_05.avif 460w", "/en/static/347475bf75ca6ac16e001fdd15a2aec9/7f308/MQTT_Dashboard_Android_05.avif 920w", "/en/static/347475bf75ca6ac16e001fdd15a2aec9/e1c99/MQTT_Dashboard_Android_05.avif 1380w", "/en/static/347475bf75ca6ac16e001fdd15a2aec9/76ea5/MQTT_Dashboard_Android_05.avif 1840w", "/en/static/347475bf75ca6ac16e001fdd15a2aec9/95b77/MQTT_Dashboard_Android_05.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/347475bf75ca6ac16e001fdd15a2aec9/a0b58/MQTT_Dashboard_Android_05.webp 230w", "/en/static/347475bf75ca6ac16e001fdd15a2aec9/bc10c/MQTT_Dashboard_Android_05.webp 460w", "/en/static/347475bf75ca6ac16e001fdd15a2aec9/966d8/MQTT_Dashboard_Android_05.webp 920w", "/en/static/347475bf75ca6ac16e001fdd15a2aec9/445df/MQTT_Dashboard_Android_05.webp 1380w", "/en/static/347475bf75ca6ac16e001fdd15a2aec9/78de1/MQTT_Dashboard_Android_05.webp 1840w", "/en/static/347475bf75ca6ac16e001fdd15a2aec9/8df42/MQTT_Dashboard_Android_05.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/347475bf75ca6ac16e001fdd15a2aec9/81c8e/MQTT_Dashboard_Android_05.png 230w", "/en/static/347475bf75ca6ac16e001fdd15a2aec9/08a84/MQTT_Dashboard_Android_05.png 460w", "/en/static/347475bf75ca6ac16e001fdd15a2aec9/c0255/MQTT_Dashboard_Android_05.png 920w", "/en/static/347475bf75ca6ac16e001fdd15a2aec9/b1001/MQTT_Dashboard_Android_05.png 1380w", "/en/static/347475bf75ca6ac16e001fdd15a2aec9/161ec/MQTT_Dashboard_Android_05.png 1840w", "/en/static/347475bf75ca6ac16e001fdd15a2aec9/a878e/MQTT_Dashboard_Android_05.png 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/347475bf75ca6ac16e001fdd15a2aec9/c0255/MQTT_Dashboard_Android_05.png",
              "alt": "INSTAR MQTT unter Android",
              "title": "INSTAR MQTT unter Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`For the Area sensitivity we can use the `}<strong parentName="p">{`Progress`}</strong>{` UI element. And again, add the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`MQTT Topic`}</a>{` and the available values this switch can have.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0ade11f9d3cad02bb664bc2ae37c19a6/71c1d/MQTT_Dashboard_Android_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAwUlEQVQY042OQW6DMBREuUDc/4lUGhJLyG4jbHAcGwzYIMIC1F0X3P8wjbLIkuStZvNmJmqaRmvtve+6bhgG7338NtE4jsaYaZpCCFLKuq7378vPBACEkB0hECM8iPcveqKyLJVSpVKFkJn8Of1W5m/q+z54TylFxC1Za922rXPuUih2FXQNxXqz1urKZN8MYVN2zi3LMi9zZW125p8XlhQsTdMTpYfjId5e5pzneS6EYJx/JQnsCH4AIiLA9ue7/A8mVi1SfJ4reAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0ade11f9d3cad02bb664bc2ae37c19a6/e4706/MQTT_Dashboard_Android_06.avif 230w", "/en/static/0ade11f9d3cad02bb664bc2ae37c19a6/d1af7/MQTT_Dashboard_Android_06.avif 460w", "/en/static/0ade11f9d3cad02bb664bc2ae37c19a6/7f308/MQTT_Dashboard_Android_06.avif 920w", "/en/static/0ade11f9d3cad02bb664bc2ae37c19a6/e1c99/MQTT_Dashboard_Android_06.avif 1380w", "/en/static/0ade11f9d3cad02bb664bc2ae37c19a6/3ff05/MQTT_Dashboard_Android_06.avif 1536w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0ade11f9d3cad02bb664bc2ae37c19a6/a0b58/MQTT_Dashboard_Android_06.webp 230w", "/en/static/0ade11f9d3cad02bb664bc2ae37c19a6/bc10c/MQTT_Dashboard_Android_06.webp 460w", "/en/static/0ade11f9d3cad02bb664bc2ae37c19a6/966d8/MQTT_Dashboard_Android_06.webp 920w", "/en/static/0ade11f9d3cad02bb664bc2ae37c19a6/445df/MQTT_Dashboard_Android_06.webp 1380w", "/en/static/0ade11f9d3cad02bb664bc2ae37c19a6/0abaa/MQTT_Dashboard_Android_06.webp 1536w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0ade11f9d3cad02bb664bc2ae37c19a6/81c8e/MQTT_Dashboard_Android_06.png 230w", "/en/static/0ade11f9d3cad02bb664bc2ae37c19a6/08a84/MQTT_Dashboard_Android_06.png 460w", "/en/static/0ade11f9d3cad02bb664bc2ae37c19a6/c0255/MQTT_Dashboard_Android_06.png 920w", "/en/static/0ade11f9d3cad02bb664bc2ae37c19a6/b1001/MQTT_Dashboard_Android_06.png 1380w", "/en/static/0ade11f9d3cad02bb664bc2ae37c19a6/71c1d/MQTT_Dashboard_Android_06.png 1536w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0ade11f9d3cad02bb664bc2ae37c19a6/c0255/MQTT_Dashboard_Android_06.png",
              "alt": "INSTAR MQTT unter Android",
              "title": "INSTAR MQTT unter Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      